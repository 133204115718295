<template>
    
    <vs-input :placeholder="placeholder" v-model="internalValue" :loading="loading" :block="block" :class="{'filled': value.length > 0}" style="overflow:hidden;" :id="id">
        <template #icon v-if="icon">
            <component :is="icon" v-if="iconType == 'svg'" />
            <i class="bx bx-sm input-icon" v-else-if="iconType == 'box-icon'" :class="icon" />
        </template>
    </vs-input>
</template>
<script>
export default {
    name: 'PtyInput',
    props: {
        id: {
            type: String,
            default: ''
        },
        
        loading: {
            type: Boolean,
            default: false
        },

        block: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: ''
        },

        icon: {
            type: [Object,String],
            default: null,
        },

        value: {
            type: String,
            default: ''
        },

        iconType: {
            type: String,
            default: 'svg'
        }
    },

    data() {
        return {
            internalValue: ''
        }
    },

    watch: {
        internalValue() {
            this.$emit('input', this.internalValue);
        },

        value() {
            this.internalValue = this.value;
        }
    }


}
</script>
<style>
.vs-input {
    font-family: 'Sofia Pro', sans-serif;
    font-weight: bold;
    border: solid 5px transparent;

    margin: 0!important;
}

.vs-input-content input {
    height: 50px!important;

    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.15625rem;
    font-weight: bold;
    border: solid 5px transparent;
    width:100%;
}

.vs-input__label {
    font-size: 1.15625rem;
    color: #000;
}

.vs-input:has(.vs-input__icon):focus {
    padding-left: 38px!important;
}

.vs-input__icon {
    box-shadow: unset!important;
    transform: unset!important;
    background: unset!important;
}

.input-icon {
    margin-left: 10px;
}

.vs-input:focus.vs-input--has-icon:not(.vs-input--has-icon--after) ~ .vs-input__icon svg > * {
    fill: rgba(var(--vs-primary))!important;
}

.vs-input-parent.filled .vs-input ~ .vs-input__icon svg > * {
    fill: rgba(var(--vs-primary))!important;
}

.vs-input-parent.filled .vs-input ~ .vs-input__icon .input-icon {
    color: rgba(var(--vs-primary))!important;
}

/* not filled  grey */
.vs-input-parent:not(.filled) .vs-input ~ .vs-input__icon .input-icon {
    color: #919191!important;
}
</style>